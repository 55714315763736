import { ImageLoader } from 'next/image';

interface getParttrapImageLoaderProps {
  bgcolor?: string;
  format?: 'jpg' | 'png';
  width?: number;
  height?: number;
}

export function getParttrapImageLoader({
  bgcolor = 'ffffff',
  format = 'jpg',
  width: inputWidth = 1440,
  height: inputHeight = 1440,
}: getParttrapImageLoaderProps = {}): ImageLoader {
  return ({ src, width, quality }) => {
    if (/^\/[_.]next\/.*$/.test(src)) {
      return src;
    }
    const url = new URL(src);
    let w = parseInt(url.searchParams.get('w') ?? String(inputWidth), 10);
    let h = parseInt(url.searchParams.get('h') ?? String(inputHeight), 10);
    const ar = w / h;
    w = Math.floor(width);
    h = Math.floor(width / ar);
    url.searchParams.set('w', String(w));
    url.searchParams.set('h', String(h));
    url.searchParams.set('bgcolor', bgcolor);
    url.searchParams.set('format', format);

    if (quality) {
      url.searchParams.set('q', String(quality));
    }
    return url.href;
  };
}

export const parttrapImageLoader: ImageLoader = ({ src, width, quality }) => {
  const url = new URL(src);
  let w = parseInt(url.searchParams.get('w') ?? '4', 10);
  let h = parseInt(url.searchParams.get('h') ?? '3', 10);
  const ar = w / h;
  w = width;
  h = width / ar;
  url.searchParams.set('w', String(w));
  url.searchParams.set('h', String(h));
  url.searchParams.set('bgcolor', 'ffffff');
  url.searchParams.set('format', 'jpg');

  if (quality) {
    url.searchParams.set('q', String(quality));
  }
  return url.href;
};
