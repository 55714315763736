import { useId } from 'react';

import dynamic from 'next/dynamic';

import { useWorkwearGuide } from '@hultafors/snickers/hooks';
import {
  isProductPlug,
  isWorkwearGuidePlug,
  ProductPlugFragment,
  ProductPlugsBlockFragment,
  WorkwearGuidePlugFragment,
} from '@hultafors/snickers/types';

import { PlugsGrid } from '../../../PlugsGrid/PlugsGrid';

const GuidePlug = dynamic(() =>
  import('../../../GuidePlug/GuidePlug').then((mod) => mod.GuidePlug),
);

const ProductPlug = dynamic(() =>
  import('../../../ProductPlug/ProductPlug').then((mod) => mod.ProductPlug),
);

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export type ProductPlugsBlockProps = Omit<
  ProductPlugsBlockFragment,
  '__typename' | 'id'
>;

export const ProductPlugsBlock: React.FC<ProductPlugsBlockProps> = ({
  plugs,
}) => {
  const uid = useId();
  const { toggle: toggleWorkwearGuide } = useWorkwearGuide();

  const plugsMapper = (
    plug: ProductPlugFragment | WorkwearGuidePlugFragment,
  ) => {
    const key = `ProductPlugsBlock-${uid}-${plug.__typename}-${plug.id}}`;
    if (isProductPlug(plug)) {
      return (
        <ProductPlug
          key={key}
          title={plug.title}
          image={plug.image}
          url={plug.link}
        />
      );
    }
    if (isWorkwearGuidePlug(plug)) {
      return (
        <GuidePlug
          key={key}
          title={plug.title}
          intro={plug.ingress}
          workwear
          onClick={toggleWorkwearGuide}
        />
      );
    }
    return null;
  };
  return <PlugsGrid>{plugs?.map(plugsMapper)}</PlugsGrid>;
};
