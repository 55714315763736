import Link from 'next/link';
import styled from 'styled-components';

import { colors } from '@hultafors/snickers/helpers';

export const LinkStyled = styled(Link)`
  color: ${colors.black};
  text-decoration: none;
  transition: 0.2s;

  &:hover {
    color: ${colors.gray1};
    transition: 0.2s;
  }

  &:focus {
    color: inherit;
    box-shadow: none !important;
    outline: 0;
    border: 3px solid -webkit-focus-ring-color !important;
  }

  &:active {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }
`;
