import dynamic from 'next/dynamic';

import { breakpoints } from '@hultafors/shared/constants';

import { ImageTextBlockFragment } from '@hultafors/snickers/types';

import { LinkButton } from '../../../LinkButton/LinkButton';
import { Markdown } from '../../../Markdown/Markdown';

import {
  Inner,
  LinkWrapper,
  MediaWrapper,
  TextContainer,
  Title,
  TitleWrapper,
} from './ImageTextBlock.styled';
import MuxPlayerContent from './mux-player-wrapper';

const ResponsiveImage = dynamic(
  () =>
    import('@hultafors/shared/components').then((mod) => mod.ResponsiveImage),
  { ssr: false },
);

export const ImageTextBlock: React.FC<ImageTextBlockFragment> = ({
  alignMediaLeft,
  textContentCenter,
  textContentRight,
  ctaLabel,
  ctaUrl,
  ctaColor,
  ctaTextColor,
  image,
  textBackgroundColor,
  textContent,
  title,
  video,
  imageLoad,
}) => {
  return (
    <div className="content">
      <Inner $alignMediaLeft={!!alignMediaLeft}>
        <TextContainer
          $alignContentCenter={!!textContentCenter}
          $alignContentRight={!!textContentRight}
          $backgroundColor={textBackgroundColor?.hex}
        >
          {title && (
            <TitleWrapper
              $alignContentCenter={!!textContentCenter}
              $alignContentRight={!!textContentRight}
            >
              <Title>{title}</Title>
            </TitleWrapper>
          )}
          {textContent && (
            <Markdown>{textContent.replace(/<\/?p>/g, '')}</Markdown>
          )}
          {ctaLabel && ctaUrl && (
            <LinkWrapper
              $linkColor={ctaColor?.hex}
              $textColor={ctaTextColor?.hex}
              $alignContentCenter={!!textContentCenter}
              $alignContentRight={!!textContentRight}
            >
              <LinkButton text={ctaLabel} url={ctaUrl} />
            </LinkWrapper>
          )}
        </TextContainer>

        {!video && image && (
          <MediaWrapper>
            <ResponsiveImage
              image={imageLoad}
              aspectRatioPortrait={1}
              aspectRatioLandscape={5 / 4}
              sizesLandscape={`(min-width: ${breakpoints.tablet}) 50vw, 100vw`}
            />
          </MediaWrapper>
        )}

        {!image && video?.video?.muxPlaybackId && (
          <MediaWrapper>
            <MuxPlayerContent video={video} id={video.video.muxPlaybackId} />
          </MediaWrapper>
        )}
      </Inner>
    </div>
  );
};
