import { useFavorites } from '@hultafors/snickers/hooks';

import { Badge } from '../Badge/Badge';

import FavoritesSvg from './favorites.svg';
import { HeaderStoreItemStyled, IconText } from './HeaderStoreItem.styled';
export interface HeaderStoreItemProps {
  isFavorite?: boolean;
  onClick: React.MouseEventHandler;
  isTransparent?: boolean;
  iconText?: string;
  children?: React.ReactNode;
  favoritesIconLabel?: string;
}

export const HeaderStoreItem: React.FC<HeaderStoreItemProps> = ({
  children,
  iconText,
  onClick,
  isFavorite,
  isTransparent,
  favoritesIconLabel,
}) => {
  const { favorites } = useFavorites();

  return (
    <HeaderStoreItemStyled
      $isFavorite={isFavorite}
      $isTransparent={isTransparent}
      onClick={onClick}
      aria-label={favoritesIconLabel}
    >
      {isFavorite && (
        <>
          <div className="Favorite">
            <FavoritesSvg aria-hidden={true} focusable={false} />
            <Badge tiny round>
              {favorites.length}
            </Badge>
          </div>
          {iconText && <IconText>{iconText}</IconText>}
          {children}
        </>
      )}
    </HeaderStoreItemStyled>
  );
};
