import { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';

import {
  ProductDetailsApiImage,
  ProductDetailsValue,
} from '@hultafors/shared/types';

import { useFavorites } from '@hultafors/snickers/hooks';
import {
  SnickersProduct,
  SnickersProductDetails,
} from '@hultafors/snickers/types';

import { Badge } from '../Badge/Badge';
import { Favorite } from '../Favorite/Favorite';
import { ProductDetails } from '../ProductDetails/ProductDetails';
import { ProductName } from '../ProductName/ProductName';
import { ProductPrice } from '../ProductPrice/ProductPrice';

import {
  Carousel,
  DetailList,
  DetailListItem,
  InfoColors,
  InfoContent,
  InfoTab,
  InfoWrapper,
  Inner,
  ProductCertificates,
  ProductHeroInfoText,
  ProductHeroStyled,
} from './ProductHero.styled';

const ProductImageCarousel = dynamic(() =>
  import('../ProductImageCarousel/ProductImageCarousel').then(
    (mod) => mod.ProductImageCarousel,
  ),
);
const DetailsViewProductColors = dynamic(() =>
  import('../DetailsViewProductColors/DetailsViewProductColors').then(
    (mod) => mod.DetailsViewProductColors,
  ),
);

export interface ProductHeroProps {
  images?: ProductDetailsApiImage[];
  product: SnickersProductDetails;
  badgeText?: string;
  rrpLabel: string;
  rrpIncludingVatLabel: string;
  setColor(color: ProductDetailsValue): void;
  color: ProductDetailsValue;
}

export const ProductHero: React.FC<ProductHeroProps> = ({
  images,
  product,
  badgeText,
  rrpLabel,
  rrpIncludingVatLabel,
  setColor,
  color,
}) => {
  const { isProductFavorite } = useFavorites();
  const pathname = usePathname();

  const [favoriteProduct, setFavoriteProduct] = useState<
    SnickersProduct | undefined
  >();

  useEffect(() => {
    const [parentSlug = '', categorySlug = ''] = pathname
      .replace(/^.*\/products\/([\w-]+\/[\w-]+)\/.*$/, '$1')
      .split('/');

    const temp: SnickersProduct = {
      categorySlug,
      image: product.images[0],
      isDefault: true,
      name: product.name,
      parentSlug,
      premiumLabel: '',
      productId: product.id,
      productListId: '23',
      sku: product.sku,
      sustainableLabel: '',
    };

    setFavoriteProduct(temp);
  }, [pathname]);

  return (
    <ProductHeroStyled>
      <Inner>
        <InfoTab>
          <InfoWrapper>
            <InfoContent>
              {product.isNew && <Badge inline>{badgeText}</Badge>}

              <ProductDetails large noBottomMargin>
                <div>
                  <span>{product.sku}</span>
                  <span>{product.category || ''}</span>
                </div>

                {favoriteProduct && (
                  <Favorite
                    // toggleFavorite={toggleFavoriteItem}
                    product={favoriteProduct}
                    isFavorite={isProductFavorite(product.sku)}
                    inFavorites={false}
                  />
                )}
              </ProductDetails>

              <ProductName>{product.name}</ProductName>
              {product?.price?.value ? (
                <ProductPrice extra={rrpLabel}>
                  {`${product.price.value} ${product.price.currency}`}
                </ProductPrice>
              ) : (
                !!product?.priceWithVat?.value && (
                  <ProductPrice extra={rrpIncludingVatLabel}>
                    {`${product.priceWithVat.value} ${product.priceWithVat.currency}`}
                  </ProductPrice>
                )
              )}
              {!!product.certifications?.length && (
                <ProductCertificates>
                  {product.certifications.map((item, i) => {
                    return (
                      item.image?.url && (
                        <img
                          key={`Cert-Icon-${i}`}
                          src={item.image.url}
                          alt={item.name || item.text || 'Certification'}
                        />
                      )
                    );
                  })}
                </ProductCertificates>
              )}
              <ProductHeroInfoText>{product.intro || ''}</ProductHeroInfoText>
              {(product.fit?.name || product.fit?.value) && (
                <DetailList>
                  <DetailListItem>
                    {product.fit.name}:{product.fit.value}
                  </DetailListItem>
                </DetailList>
              )}
            </InfoContent>
            <InfoColors>
              <DetailsViewProductColors
                colors={product.colors}
                setColor={setColor}
                color={color}
              />
            </InfoColors>
          </InfoWrapper>
        </InfoTab>
        <Carousel>
          <ProductImageCarousel images={images} alt={product.name} />
        </Carousel>
      </Inner>
    </ProductHeroStyled>
  );
};
