import dynamic from 'next/dynamic';

import { breakpoints } from '@hultafors/shared/constants';

import {
  DualImageFragment,
  ImageLoadFragment,
} from '@hultafors/snickers/types';

import { DoubleImageStyled, ImageContainer } from './DoubleImageBlock.styled';

const DatoImage = dynamic(
  () => import('@hultafors/shared/components').then((mod) => mod.DatoImage),
  { ssr: false },
);

export const DoubleImageBlock: React.FC<DualImageFragment> = ({
  dualImage: images,
  dualImageImages: imageLoadImages,
}) => {
  const imageMapper = (image: ImageLoadFragment, index: number) => {
    return (
      <ImageContainer key={`dual-image-${index}`}>
        <DatoImage
          image={image}
          aspectRatio={1}
          fill
          sizes={`(min-width: ${breakpoints.mobileMax}) 50vw, 100vw`}
        />
      </ImageContainer>
    );
  };
  return (
    <DoubleImageStyled>{imageLoadImages.map(imageMapper)}</DoubleImageStyled>
  );
};
