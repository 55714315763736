import { createUrl } from '@hultafors/snickers/helpers';
import { MenuItemFragment } from '@hultafors/snickers/types';

const secondColumnItemIDs = [
  'OQF2OcldTHanZ69mZwd1gg',
  'dNJqbH3MQ9SByUhGj1TE3w',
  'EiFmDul6QGGmQfTAEZjwQQ',
];

export const secondColumnFilter = (castItem: MenuItemFragment) => {
  return (
    castItem?.pageLink?.slug &&
    secondColumnItemIDs.includes(castItem.pageLink.id)
  );
};

export const otherColumnsFilter = (castItem: MenuItemFragment) => {
  return (
    castItem?.pageLink?.slug &&
    !secondColumnItemIDs.includes(castItem.pageLink.id)
  );
};

export function getHref(item: MenuItemFragment): string {
  if (item.pageLink?.__typename === 'CategoryPageRecord') {
    return `/products/${item.pageLink?.slug}`;
  }
  if (item.newUrl) {
    return createUrl(item.newUrl);
  }
  return '';
}

export function getActive(item: MenuItemFragment, asPath: string): boolean {
  if (!asPath || asPath === '/') {
    return false;
  }
  if (item.pageLink?.__typename === 'CategoryPageRecord') {
    return asPath.startsWith('/products');
  }
  if (item.newUrl) {
    return asPath.startsWith(createUrl(item.newUrl));
  }
  return false;
}
