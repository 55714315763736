import { LinkButtonStyled } from './LinkButton.styled';

export interface LinkButtonProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  text: string;
  url?: string;
  mailTo?: boolean;
  large?: boolean;
  mailToLink?: string;
  mailSubject?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  text = '',
  url = '',
  mailTo = false,
  mailToLink = '',
  large,
  className,
  style,
  ...props
}) => (
  <LinkButtonStyled
    href={mailTo ? `mailto:${mailToLink}` : url}
    $large={large}
    className={className}
    style={style}
    {...props}
  >
    {text}
  </LinkButtonStyled>
);
