import dynamic from 'next/dynamic';
import Image from 'next/image';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useGlobal } from '@hultafors/snickers/hooks';

// import LogoSvg from './logo-small.svg';
import styles from './footer.module.scss';
import LogoSvg from './snickers-workwear-50-emblem.svg';

const FooterStyled = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.Footer),
);

const FooterOld = dynamic(() =>
  import('../FooterOld/FooterOld').then((mod) => mod.FooterOld),
);

export const Footer = () => {
  const { footer, global } = useGlobal();
  const { toggle, activeMarket } = useMarketPicker();
  const datoLogo = footer?.footerLogo?.url;

  if (!footer.useNewFooter) {
    return <FooterOld />;
  }
  // TODO remove svg when using datoLogo

  const Logo = () => (
    <figure className={styles['logo']}>
      {datoLogo ? (
        <Image
          src={datoLogo}
          fill
          alt="Snickers Workwear"
          style={{ objectFit: 'contain' }}
        />
      ) : (
        <LogoSvg aria-hidden={true} focusable={false} width={48} height={48} />
      )}
    </figure>
  );
  return (
    <FooterStyled
      activeMarket={activeMarket}
      bottomIntro={footer?.bottomIntro ?? ''}
      brandLinks={footer?.hultaforsBrands}
      className={styles['footer']}
      cookieSettingsLabel={footer?.cookieSettings}
      introText={footer.introText ?? ''}
      items={footer?.menuItems}
      logoAriaLabel="Snickers Workwear"
      logoIcon={<Logo />}
      moreInfoText={footer?.moreInfoText ?? ''}
      selectCountryLabel={
        footer.selectCountryLabel || global?.changeLanguage || ''
      }
      socialMediaLabel={footer?.socialMediaLabel}
      socialMediaMenu={footer?.socialMediaMenu}
      toggleMarketPicker={toggle}
    />
  );
};
