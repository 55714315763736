import { startTransition, useEffect, useRef, useState } from 'react';

import Image from 'next/image';
import { useScroll } from 'react-use';

import { useMarketPicker } from '@hultafors/shared/context/hooks';

import { useGlobal } from '@hultafors/snickers/hooks';
import { GlobalFields } from '@hultafors/snickers/types';

import {
  DESKTOP_MENU_DRAWER_ID,
  DesktopMenu,
} from '../DesktopMenu/DesktopMenu';
import { DesktopMenuType } from '../DesktopMenu/DesktopMenu-old';
import { HeaderStoreItem } from '../HeaderStoreItem/HeaderStoreItem';
import { SearchBox } from '../SearchBox/SearchBox';
import { SlideIn, SlideInToggler } from '../SlideIn/SlideIn';

import LanguageSvg from './language.svg';
import LogoPng from './logo.png';
import MenuTogglerSvg from './menuToggler.svg';
import {
  ChangeCountry,
  DesktopNav,
  Logo,
  MenuActions,
  MenuContentItem,
  MenuToggler,
  MobileNav,
  NavigationStyled,
  Partner,
  Search,
  TopActions,
  TopActionsLabel,
} from './Navigation.styled';
import PartnerSvg from './partner.svg';
import SearchSvg from './search.svg';

export interface NavigationProps {
  disabled?: boolean;
  isTransparent?: boolean;
  isOpen?: boolean;
  productMenu?: any;
  toggleMenu: SlideInToggler;
  toggleDesktopMenu: SlideInToggler<DesktopMenuType>;
  toggleFavoritesMenu: SlideInToggler;
  openDesktopMenuType?: DesktopMenuType | null;
  menuData?: GlobalFields['allMenuItems'];
  menuExtras?: GlobalFields['menuExtra'];
}

export const Navigation: React.FC<NavigationProps> = ({
  toggleDesktopMenu,
  toggleMenu,
  toggleFavoritesMenu,
  isTransparent,
  isOpen,
  openDesktopMenuType,
  menuData,
  menuExtras,
}) => {
  const [scrolledDown, setScrolledDown] = useState<boolean>(false);
  const comp = useRef(null);
  const { global } = useGlobal();
  const scrollRef = useRef(null);
  const { y } = useScroll(scrollRef);
  const transparent = isTransparent && !scrolledDown && !isOpen;
  const { toggle, activeMarket } = useMarketPicker();
  const datoLogo = global?.navigationLogo?.url;
  const logo = datoLogo ? datoLogo : LogoPng;

  function renderLanguageIcon() {
    let iconUrl;

    if (!activeMarket || (!activeMarket?.flag && !activeMarket?.symbol?.url)) {
      return <LanguageSvg focusable={false} aria-hidden={true} height={24} />;
    }
    if (activeMarket?.flag && !activeMarket?.symbol?.url) {
      iconUrl = `https://flagcdn.com/${activeMarket.flag}.svg`;
    }
    if (activeMarket?.symbol?.url) {
      iconUrl = activeMarket.symbol.url;
    }
    return (
      <img
        src={iconUrl ?? ''}
        width={24}
        height={24}
        alt={activeMarket.name ?? ''}
      />
    );
  }

  useEffect(() => {
    if (comp.current && isTransparent) {
      startTransition(() => {
        setScrolledDown(y > 100);
      });
    }
  }, [y]);

  const menuTogglerOnClick = () => toggleMenu();
  const favoritesTogglerOnClick = () => toggleFavoritesMenu();
  function onClickSearch(): void {
    toggleDesktopMenu('search');
  }

  return (
    <NavigationStyled
      $transparent={isTransparent && !scrolledDown && !isOpen}
      ref={comp}
    >
      <MobileNav>
        <MenuToggler
          onClick={menuTogglerOnClick}
          aria-label={global?.menuLabel || ''}
        >
          <MenuTogglerSvg
            width={20}
            height={20}
            aria-hidden={true}
            focusable={false}
          />
        </MenuToggler>

        <Logo href="/">
          <Image
            src={logo}
            fill
            alt="Snickers Workwear"
            priority={true}
            sizes="75px"
          />
        </Logo>

        <MenuActions>
          <ChangeCountry
            onClick={() => toggle(true)}
            aria-label={global?.changeLanguage || 'Change language'}
          >
            {renderLanguageIcon()}
            <TopActionsLabel $transparent={transparent}></TopActionsLabel>
          </ChangeCountry>
          <HeaderStoreItem
            isFavorite
            onClick={favoritesTogglerOnClick}
            favoritesIconLabel={global?.favoritesHeader || 'Favorites'}
          />
        </MenuActions>
      </MobileNav>

      <DesktopNav>
        <Logo href="/">
          <Image
            src={logo}
            fill
            alt="Snickers Workwear"
            priority={true}
            sizes="75px"
          />
        </Logo>
        <DesktopMenu
          toggleDesktopMenu={toggleDesktopMenu}
          menuItems={menuData}
          menuExtras={menuExtras}
        />
        <TopActions $transparent={transparent}>
          <ChangeCountry
            onClick={() => toggle(true)}
            aria-label={global?.changeLanguage || 'Change language'}
          >
            {renderLanguageIcon()}
            <TopActionsLabel $transparent={transparent}>
              {global?.changeLanguage || ''}
            </TopActionsLabel>
          </ChangeCountry>
          <Search
            onClick={onClickSearch}
            data-slidein-ignore={DESKTOP_MENU_DRAWER_ID}
            aria-label={global?.search || 'Search'}
          >
            <SearchSvg focusable={false} aria-hidden={true} height={24} />
            <TopActionsLabel $transparent={transparent}>
              {global?.search || ''}
            </TopActionsLabel>
          </Search>
          <SlideIn
            toggle={onClickSearch}
            isOpen={openDesktopMenuType === 'search'}
            fromTop
            small={true}
            id={DESKTOP_MENU_DRAWER_ID}
          >
            <MenuContentItem>
              <SearchBox
                wide
                toggleMenu={toggleDesktopMenu}
                isOpen={openDesktopMenuType === 'search'}
                isAutoComplete={true}
              />
            </MenuContentItem>
          </SlideIn>
          <HeaderStoreItem
            isFavorite
            onClick={favoritesTogglerOnClick}
            isTransparent={isTransparent && !scrolledDown && !isOpen}
            iconText={global?.favoritesHeader || ''}
            favoritesIconLabel={global?.favoritesHeader || 'Favorites'}
          />
          {global?.partnerPortalUrl && (
            <Partner
              href={global?.partnerPortalUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PartnerSvg focusable={false} aria-hidden={true} height={24} />
              <TopActionsLabel>Partner</TopActionsLabel>
            </Partner>
          )}
        </TopActions>
      </DesktopNav>
    </NavigationStyled>
  );
};
