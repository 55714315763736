import { H1Styled } from './H1.styled';

export interface H1Props {
  children?: React.ReactNode;
  className?: string;
  center?: boolean;
  uppercase?: boolean;
}

export const H1: React.FC<H1Props> = ({
  center,
  className,
  children,
  uppercase,
}) => {
  return (
    <H1Styled className={className} $center={center} $uppercase={uppercase}>
      {children}
    </H1Styled>
  );
};
