import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { SwiperSlide } from 'swiper/react';

import { useGlobal } from '@hultafors/snickers/hooks';
import {
  ImageLoadFragment,
  ImageSliderFragment,
  SliderBlockFragment,
} from '@hultafors/snickers/types';

import styles from './image-slider-block.module.scss';

const DatoImage = dynamic(
  () => import('@hultafors/shared/components').then((mod) => mod.DatoImage),
  { ssr: false },
);

const SliderBlockShared = dynamic(() =>
  import('@hultafors/shared/components').then((mod) => mod.SliderBlockShared),
);

export const ImageSliderBlock: React.FC<ImageSliderFragment> = ({
  imageSliderImages,
  imageSlider,
}) => {
  const { global } = useGlobal();

  const sliderBlockProps: Partial<SliderBlockFragment> = {
    arrows: true,
    slideAnimation: true,
  };

  function imageMapper(image: ImageLoadFragment, index: number) {
    return (
      <SwiperSlide key={`ImageSliderBlock-${index}`} className="swiper-slide">
        <DatoImage image={image} aspectRatio={16 / 9} />
      </SwiperSlide>
    );
  }

  return (
    <div className={clsx('content', styles['image-slider-block'])}>
      <SliderBlockShared
        className={styles['slider-block']}
        blockProps={sliderBlockProps}
        ariaLabelNext={global?.nextLabel || ''}
        ariaLabelPrevious={global?.previousLabel || ''}
        ariaLabelSliderNavigation={global?.sliderNavigationLabel || ''}
        loop={false}
      >
        {imageSliderImages.map(imageMapper)}
      </SliderBlockShared>
    </div>
  );
};
