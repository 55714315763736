import styled from 'styled-components';

import {
  fontFamilies,
  fontSizes,
  fontWeights,
  lineHeights,
} from '@hultafors/snickers/helpers';

interface H1StyledProps {
  $center?: boolean;
  $uppercase?: boolean;
}

export const H1Styled = styled.h1<H1StyledProps>`
  font-family: ${fontFamilies.mikro};
  font-size: ${fontSizes.h1};
  line-height: ${lineHeights.xs};
  font-weight: ${fontWeights.bold};
  text-align: ${({ $center }) => ($center ? 'center' : '')};
  letter-spacing: 1px;
  text-transform: ${({ $uppercase }) => ($uppercase ? 'uppercase' : 'none')};
`;
