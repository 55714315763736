import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

export const DoubleImageStyled = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.mobileMax}) {
    flex-direction: row;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 1rem;
  }
`;

export const ImageContainer = styled.div`
  inline-size: 100%;
`;
