import dynamic from 'next/dynamic';

import { breakpoints } from '@hultafors/shared/constants';

import { ImageLoadFragment } from '@hultafors/snickers/types';

import { Paragraph } from '../Paragraph/Paragraph';

import {
  BackgroundColors,
  Content,
  GenericLargePlugStyled,
  NameText,
  PlugIngress,
  Title,
} from './GenericLargePlug.styled';
import LinkSvg from './link.svg';

const ResponsiveImage = dynamic(
  () =>
    import('@hultafors/shared/components').then((mod) => mod.ResponsiveImage),
  { ssr: false },
);

export interface GenericLargePlugProps {
  title?: string | null;
  plugIngress?: string | null;
  image?: ImageLoadFragment | null;
  label?: string | null;
  name?: string | null;
  url?: string | null;
  className?: string | null;
  sizes?: string | null;
  sizesDesktop?: string | null;
}

export const GenericLargePlug: React.FC<GenericLargePlugProps> = ({
  url,
  image,
  name,
  plugIngress,
  title,
  label,
  sizes: inputSizes,
  sizesDesktop: inputSizesDesktop,
}) => {
  const sizes = inputSizes ?? '100vw';
  const sizesDesktop =
    inputSizesDesktop ??
    [
      `(max-width: ${breakpoints.maxPageWidth}) 100vw`,
      `${breakpoints.maxPageWidth}`,
    ].join(', ');

  return (
    <GenericLargePlugStyled $image={!!image} href={url ?? ''}>
      {image?.url ? (
        <ResponsiveImage
          image={image}
          sizesLandscape={sizesDesktop}
          sizesPortrait={sizes}
          aspectRatioPortrait={3 / 4}
          aspectRatioLandscape={1}
        />
      ) : (
        <BackgroundColors className="backgroundColors" />
      )}
      <Content $image={!!image}>
        {label && <Paragraph>{label}</Paragraph>}
        {name && <NameText $image={!!image}>{name}</NameText>}
        <Title $image={!!image}>{title}</Title>
        {plugIngress && <PlugIngress>{plugIngress}</PlugIngress>}
        {url && (
          <LinkSvg
            width={39}
            height={18}
            aria-hidden={true}
            focusable={false}
          />
        )}
      </Content>
    </GenericLargePlugStyled>
  );
};
