import Markdown from 'markdown-to-jsx';
import dynamic from 'next/dynamic';

import { useGlobal } from '@hultafors/snickers/hooks';
import { VideoBlockFragment } from '@hultafors/snickers/types';

import MuxPlayerContent from './mux-player-wrapper';
import styles from './videoBlock.module.scss';

const LinkButton = dynamic(() =>
  import('../../../LinkButton/LinkButton').then((module) => module.LinkButton),
);

export interface VideoBlockProps
  extends Omit<VideoBlockFragment, '__typename' | 'id'> {
  priority?: boolean;
}

export const VideoBlock: React.FC<VideoBlockProps> = ({
  ctaLink,
  ctaText,
  video,
  priority,
  posterImage,
  title,
  ctaColor,
}) => {
  const { global } = useGlobal();
  const HeadingTag = priority ? 'h1' : 'h2';

  return (
    <div className={`${styles['video-block']}`}>
      <MuxPlayerContent
        video={video}
        posterImage={posterImage}
        title={title}
        muteLabel={global?.muteLabel || 'Mute video'}
      />
      <div className={styles['content-container']}>
        {title && (
          <HeadingTag
            className={`${styles['title']} ${styles['title-' + HeadingTag]}`}
          >
            <Markdown>{title}</Markdown>
          </HeadingTag>
        )}
        {ctaText && ctaLink && (
          <LinkButton
            url={ctaLink}
            text={ctaText}
            large
            className={styles['link-button']}
            style={{
              backgroundColor: ctaColor?.hex,
              borderColor: ctaColor?.hex,
            }}
          />
        )}
      </div>
    </div>
  );
};
