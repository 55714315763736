import React, { useState } from 'react';

import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import type { NavigationMenuItemClassNames } from '@hultafors/shared/components/navigation-menu-item/navigation-menu-item';
import { useLockBodyScroll } from '@hultafors/shared/hooks';

import {
  createProductsMenuUrl,
  recordTypes,
} from '@hultafors/snickers/helpers';
import { useGlobal } from '@hultafors/snickers/hooks';
import { GlobalFields, MenuItemFragment } from '@hultafors/snickers/types';

import {
  getActive,
  getHref,
  otherColumnsFilter,
  secondColumnFilter,
} from './desktop-menu';
import styles from './desktop-menu.module.scss';

const NavigationMenu = dynamic(() =>
  import('@hultafors/shared/components/navigation-menu/navigation-menu').then(
    (module) => module.NavigationMenu,
  ),
);

const NavigationMenuItem = dynamic(() =>
  import(
    '@hultafors/shared/components/navigation-menu-item/navigation-menu-item'
  ).then((module) => module.NavigationMenuItem),
);

const NavigationMenuLink = dynamic(() =>
  import(
    '@hultafors/shared/components/navigation-menu-item/navigation-menu-item'
  ).then((module) => module.NavigationMenuLink),
);

export const DESKTOP_MENU_DRAWER_ID = 'DesktopMenuDrawer';
export type DesktopMenuType = 'search' | 'CategoryPageRecord';

export interface DesktopMenuProps {
  menuItems?: GlobalFields['allMenuItems'];
  menuExtras?: GlobalFields['menuExtra'];
  toggleDesktopMenu(...args: any[]): unknown;
}

export const DesktopMenu: React.FC<DesktopMenuProps> = ({
  menuItems,
  menuExtras,
  toggleDesktopMenu,
}) => {
  const { settings } = useGlobal();
  const { asPath } = useRouter();
  const subMenuItems = menuItems?.filter((item) => !!item.children.length);
  const firstColItems = menuExtras?.productMenu;
  const isUsMarket = settings?.market === 'us';
  const [active, setActive] = useState<string>('');
  useLockBodyScroll(Boolean(active));

  const classNames: NavigationMenuItemClassNames = {
    activeLink: styles['active'],
    link: styles['navigation-menu-link'],
    trigger: styles['navigation-menu-trigger'],
  };

  const menuItemMapper = (castItem: MenuItemFragment) => {
    if (castItem.hide || castItem.hideOnDesktop) {
      return null;
    }

    return (
      <li
        key={`DesktopMenuItem-${castItem.id}`}
        className={styles['columns-list']}
      >
        <NavigationMenuLink
          href={createProductsMenuUrl({
            currentNode: castItem,
          })}
          className={styles['cast-item-menu-link']}
          asChild
        >
          {castItem.title}
        </NavigationMenuLink>
        {castItem.children.map((subItem) => {
          if (subItem.hide || subItem.hideOnDesktop) return null;
          return (
            <NavigationMenuLink
              key={`DesktopMenuItemLink-${subItem.id}`}
              href={createProductsMenuUrl({
                currentNode: subItem,
                parentNode: castItem,
              })}
              className={styles['sub-item-menu-link']}
              asChild
            >
              {subItem.title || ''}
            </NavigationMenuLink>
          );
        })}
      </li>
    );
  };

  const FirstRowItems: React.FC<{
    castItem: MenuItemFragment;
    children: React.ReactNode;
  }> = ({ children, castItem }) => {
    if (castItem.hide || castItem.hideOnDesktop) return null;
    const hasSubmenuItems = castItem.children.length;

    const handleClick = () => {
      setActive(active ? '' : castItem.id);
      toggleDesktopMenu(recordTypes.CATEGORY);
    };

    if (hasSubmenuItems)
      return (
        <NavigationMenuItem
          key={`DesktopMenuItem-${castItem.id}`}
          active={getActive(castItem, asPath)}
          triggerLabel={castItem.title}
          href={getHref(castItem)}
          subLinks
          classNames={classNames}
          value={active}
          onClick={handleClick}
        >
          {firstColItems && (
            <ul className={styles['columns-list']}>
              {firstColItems.map(({ id, url, title }) => (
                <li key={`TabItems-${id}`} className={styles['first-col-list']}>
                  <NavigationMenuLink
                    className={clsx(styles['cast-item-menu-link'])}
                    href={url || ''}
                    asChild
                  >
                    {title || ''}
                  </NavigationMenuLink>
                </li>
              ))}
            </ul>
          )}
          {children}
        </NavigationMenuItem>
      );
    else
      return (
        <NavigationMenuItem
          key={`DesktopMenuItem-${castItem.id}`}
          href={getHref(castItem)}
          active={getActive(castItem, asPath)}
          classNames={classNames}
        >
          {castItem.title}
        </NavigationMenuItem>
      );
  };

  const FilterNotUsMarketItems: React.FC<MenuItemFragment> = ({
    id,
    children,
  }) => {
    return (
      <ul key={`DesktopMenuSecondCol-${id}`} className={styles['columns-list']}>
        {children
          ?.filter(Boolean)
          .filter((item) => item !== (item.hide || item.hideOnDesktop))
          .filter((item) => secondColumnFilter(item as MenuItemFragment))
          .map((item) => menuItemMapper(item as MenuItemFragment))}
      </ul>
    );
  };

  const SubItems: React.FC<MenuItemFragment> = ({ children }) => {
    return children
      ?.filter(Boolean)
      .filter((item) => item !== (item.hide || item.hideOnDesktop))
      .filter((item) => otherColumnsFilter(item as MenuItemFragment))
      .map((item) => {
        return (
          <ul
            key={`DesktopMenuOtherCol-${item.id}`}
            className={styles['columns-list']}
          >
            {menuItemMapper(item as MenuItemFragment)}
          </ul>
        );
      });
  };

  return (
    <NavigationMenu
      className={clsx(styles['root'], active && styles['overlay'])}
      value={active}
      onValueChange={() => setActive(active ? '' : active)}
    >
      {menuItems?.map((firstRowItem) => {
        if (firstRowItem.hide || firstRowItem.hideOnDesktop) return null;

        return (
          <FirstRowItems castItem={firstRowItem}>
            {subMenuItems?.map((subItem) => {
              return (
                <>
                  {!isUsMarket && (
                    <FilterNotUsMarketItems
                      children={subItem?.children}
                      id={subItem.id}
                    />
                  )}

                  <SubItems children={subItem.children} id={subItem.id} />
                </>
              );
            })}
          </FirstRowItems>
        );
      })}
    </NavigationMenu>
  );
};
