import dynamic from 'next/dynamic';

import { PersonInfoFragment } from '@hultafors/snickers/types';

import { Paragraph } from '../../../Paragraph/Paragraph';

import {
  BylineWrapper,
  Container,
  Details,
  DetailsWrapper,
  IntervieweeName,
} from './PersonInfoBlock.styled';

type PersonInfoProps = Omit<PersonInfoFragment, 'id'>;

const DatoImage = dynamic(
  () => import('@hultafors/shared/components').then((mod) => mod.DatoImage),
  { ssr: false },
);

export const PersonInfoBlock: React.FC<PersonInfoProps> = ({
  age = 0,
  ageLabel = 'Age',
  family = '',
  familyLabel = 'Family',
  favoriteGarment = '',
  favoriteGarmentLabel = 'Favorite garment',
  image,
  imageLoad,
  lives = '',
  name,
  placeOfWork = '',
  profession = '',
  professionLabel = 'Profession',
  size = '',
  sizeLabel = 'Size',
  whereILiveLabel = 'Where I live',
  workplaceLabel = 'Place of work',
}) => {
  const sizes = [
    '(min-width: 1025px) 455px',
    '(min-width: 980px) 460px',
    '(min-width: 600px) 50vw',
    '100vw',
  ].join(', ');
  return (
    <Container>
      <BylineWrapper>
        <DatoImage image={imageLoad} sizes={sizes} aspectRatio={1} />
      </BylineWrapper>
      <DetailsWrapper>
        <Details>
          {name && <IntervieweeName>{name}</IntervieweeName>}
          {size && (
            <Paragraph>
              <span className="IntervieweeLabel">{sizeLabel}: </span>
              {size}
            </Paragraph>
          )}
          {age && (
            <Paragraph>
              <span className="IntervieweeLabel">{ageLabel}: </span>
              {age}
            </Paragraph>
          )}
          {family && (
            <Paragraph>
              <span className="IntervieweeLabel">{familyLabel}: </span>
              {family}
            </Paragraph>
          )}
          {lives && (
            <Paragraph>
              <span className="IntervieweeLabel">{whereILiveLabel}: </span>
              {lives}
            </Paragraph>
          )}
          {profession && (
            <Paragraph>
              <span className="IntervieweeLabel">{professionLabel}: </span>
              {profession}
            </Paragraph>
          )}
          {placeOfWork && (
            <Paragraph>
              <span className="IntervieweeLabel">{workplaceLabel}: </span>
              {placeOfWork}
            </Paragraph>
          )}
          {favoriteGarment && (
            <Paragraph>
              <span className="IntervieweeLabel">{favoriteGarmentLabel}: </span>
              {favoriteGarment}
            </Paragraph>
          )}
        </Details>
      </DetailsWrapper>
    </Container>
  );
};
