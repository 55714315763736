import clsx from 'clsx';
import Markdown from 'markdown-to-jsx';
import dynamic from 'next/dynamic';

import {
  useGlobal,
  useSizeGuide,
  useWorkwearGuide,
} from '@hultafors/snickers/hooks';
import { HeroBlockFragment } from '@hultafors/snickers/types';

import styles from './hero-block.module.scss';
import NextSvg from './next.svg';

const DoubleColorText = dynamic(() =>
  import('../DoubleColorText/DoubleColorText').then(
    (module) => module.DoubleColorText,
  ),
);

const H1 = dynamic(() => import('../H1/H1').then((module) => module.H1));

const H2 = dynamic(() => import('../H2/H2').then((module) => module.H2));
const H3 = dynamic(() => import('../H3/H3').then((module) => module.H3));

const Paragraph = dynamic(() =>
  import('../Paragraph/Paragraph').then((module) => module.Paragraph),
);

const ResponsiveImage = dynamic(
  () =>
    import('@hultafors/shared/components').then((mod) => mod.ResponsiveImage),
  { ssr: false },
);

const InfoBox = dynamic(() =>
  import('../InfoBox/InfoBox').then((module) => module.InfoBox),
);

const LinkButton = dynamic(() =>
  import('../LinkButton/LinkButton').then((module) => module.LinkButton),
);

const SearchBox = dynamic(() =>
  import('../SearchBox/SearchBox').then((module) => module.SearchBox),
);

export interface HeroBlockProps extends HeroBlockFragment {
  priority?: boolean;
  noGradient?: boolean;
  wide?: boolean;
}

export const HeroBlock: React.FC<HeroBlockProps> = ({
  alignContentLeft,
  boxColor,
  ctaLink,
  ctaText,
  secondCtaLink,
  secondCtaText,
  thirdCtaLink,
  thirdCtaText,
  description,
  doubleColorTitle,
  heroContentBoxBig,
  heroContentBoxSmall,
  guideIconImage,
  guideIconTitle,
  image,
  imageLoad,
  imageThin,
  mobileImage,
  preTitle,
  searchBox,
  sizeGuideButton,
  subTitle,
  tabletImage,
  title,
  workWearButton,
  priority,
  noGradient,
  wide,
  thin,
  limitWidth,
  ctaColor,
}) => {
  const { global } = useGlobal();
  const { toggle: toggleWorkwearGuide } = useWorkwearGuide();
  const { toggle: toggleSizeGuide } = useSizeGuide();
  const showDoubleColorText = !heroContentBoxBig && title && doubleColorTitle;
  const showTitle = !heroContentBoxBig && title && !doubleColorTitle;
  const showCtaButtons = ctaLink && ctaText;
  const showDescription =
    description && !heroContentBoxSmall && !heroContentBoxBig;
  const isLimitWidth = !priority && limitWidth;

  const heroBlockClassNames = clsx(
    styles['hero-block'],
    !heroContentBoxSmall && !heroContentBoxBig && styles['hero-block--margin'],
  );

  const heroBlockWrapperClassNames = clsx(
    styles['hero-block-wrapper'],
    imageLoad && styles['hero-block-wrapper--image'],
    thin && styles['hero-block-wrapper--thin'],
    imageLoad && !noGradient && styles['hero-block-wrapper--gradient'],
  );

  const titleClassNames = clsx(
    styles['title'],
    !!imageLoad && styles['title--shadow'],
    wide && styles['title--wide'],
    alignContentLeft && styles['title--with-icon'],
    alignContentLeft && styles['title--align-left'],
    !showDescription && styles['title--margin'],
  );

  const onClickGuide = () => {
    if (sizeGuideButton && !workWearButton) {
      toggleSizeGuide();
    }
    if (!sizeGuideButton && workWearButton) {
      toggleWorkwearGuide();
    }
  };

  let TitleComponent = H2;
  if (priority) {
    TitleComponent = H1;
  }

  if (!imageLoad && !title) {
    return null;
  }

  const ctaButtonsMapper = () => {
    const ctaButtons = [
      { link: ctaLink, text: ctaText },
      { link: secondCtaLink, text: secondCtaText },
      { link: thirdCtaLink, text: thirdCtaText },
    ];

    return ctaButtons
      .filter((a) => a.link && a.text)
      .map((item, i) => {
        if (!item.link || !item.text) return null;
        return (
          <LinkButton
            key={`cta-button-${i}`}
            large
            text={item?.text}
            url={item?.link}
            style={{
              backgroundColor: ctaColor?.hex,
              borderColor: ctaColor?.hex,
            }}
          />
        );
      });
  };

  return (
    <div className={heroBlockClassNames}>
      <div
        className={clsx(isLimitWidth ? 'grid' : 'grid-wide', styles['grid'])}
      >
        <div
          className={
            isLimitWidth ? styles['grid-item'] : styles['grid-item--wide']
          }
        >
          <div className={heroBlockWrapperClassNames}>
            {imageLoad && (
              <ResponsiveImage
                image={imageLoad}
                alt={title}
                aspectRatioPortrait={1}
                aspectRatioLandscape={thin ? 32 / 9 : 12 / 5}
                priority={priority}
                isBackground
              />
            )}
            <div
              className={clsx(
                styles['hero-block-inner'],
                alignContentLeft && styles['hero-block-inner--left'],
              )}
            >
              {preTitle && (
                <H3 uppercase bottomSpacing="small">
                  {preTitle}
                </H3>
              )}
              {showDoubleColorText && <DoubleColorText text={title} />}
              {showTitle && (
                <TitleComponent className={titleClassNames} uppercase>
                  <Markdown>{title}</Markdown>
                </TitleComponent>
              )}
              {subTitle && (
                <Paragraph
                  className={clsx(
                    styles['sub-title'],
                    image && styles['sub-title--image'],
                  )}
                >
                  {subTitle}
                </Paragraph>
              )}
              {showDescription && (
                <div
                  className={clsx(
                    styles['description-wrapper'],
                    alignContentLeft &&
                      styles['description-wrapper--with-line'],
                  )}
                >
                  <Paragraph
                    className={clsx(
                      styles['description'],
                      !!image && styles['description--shadow'],
                      alignContentLeft && styles['description--with-line'],
                    )}
                  >
                    {description}
                  </Paragraph>
                </div>
              )}
              {showCtaButtons && (
                <div className={styles['button-container']}>
                  {ctaButtonsMapper()}
                </div>
              )}
              {searchBox && <SearchBox transparent wide />}
            </div>
          </div>
          {heroContentBoxSmall && (
            <div
              className={styles['content-box-small']}
              style={{ backgroundColor: boxColor?.hex }}
            >
              {description}
            </div>
          )}
          {heroContentBoxBig && (
            <div className={styles['content-box-big']}>
              {sizeGuideButton && !workWearButton && (
                <div className={styles['guide-button-container']}>
                  <button
                    onClick={onClickGuide}
                    className={styles['guide-button']}
                  >
                    {global.sizeGuideButtonLabel}
                    <NextSvg aria-hidden={true} focusable={false} />
                  </button>
                </div>
              )}
              {!sizeGuideButton && workWearButton && (
                <div className={styles['guide-button-container']}>
                  <button
                    onClick={onClickGuide}
                    className={styles['guide-button']}
                  >
                    {global.workwearGuideButtonLabel}
                    <NextSvg aria-hidden={true} focusable={false} />
                  </button>
                </div>
              )}
              <TitleComponent className={styles['content-box-title']}>
                <Markdown>{title || ''}</Markdown>
              </TitleComponent>
              {subTitle && (
                <Paragraph
                  className={clsx(
                    styles['sub-title'],
                    image && styles['sub-title--image'],
                  )}
                >
                  {subTitle}
                </Paragraph>
              )}
              {guideIconImage?.url && (
                <div>
                  <InfoBox
                    title={guideIconTitle ?? ''}
                    icon={guideIconImage.url}
                  />
                </div>
              )}
              <Paragraph className={styles['content-box-description']}>
                {description}
              </Paragraph>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
