import { startTransition, useEffect, useRef, useState } from 'react';

import { useRouter } from 'next/router';
import { useDebounce, useKey } from 'react-use';
import useSWR from 'swr';

import { ParsedProductsApiResponse } from '@hultafors/shared/types';

import { SEARCH_BOX_PAGE_SIZE } from '@hultafors/snickers/api';
import { routes } from '@hultafors/snickers/helpers';
import { useGlobal } from '@hultafors/snickers/hooks';
import { SnickersProduct } from '@hultafors/snickers/types';

import { DesktopMenuType } from '../DesktopMenu/DesktopMenu';
import { Loader } from '../Loader/Loader';
import { ProductListItem } from '../ProductListItem/ProductListItem';
import { SlideInToggler } from '../SlideIn/SlideIn';

import {
  Result,
  ResultEmpty,
  ResultHeading,
  ResultLink,
  ResultLoading,
  SearchBoxStyled,
  SearchInputStyled,
} from './SearchBox.styled';

export interface SearchBoxProps {
  isAutoComplete?: boolean;
  isOpen?: boolean;
  placeholder?: string;
  toggleMenu?: SlideInToggler<DesktopMenuType>;
  passive?: boolean;
  inMobileMenu?: boolean;
  initialValue?: string;
  tall?: boolean;
  transparent?: boolean;
  wide?: boolean;
}

export const SearchBox: React.FC<SearchBoxProps> = ({
  isOpen = false,
  toggleMenu,
  placeholder,
  tall,
  transparent,
  wide,
  passive,
  inMobileMenu,
  initialValue = '',
}) => {
  const { global, settings } = useGlobal();
  const [term, setTerm] = useState(initialValue);
  const [searchValue, setSearchValue] = useState(initialValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const [, cancel] = useDebounce(
    () => {
      startTransition(() => {
        setSearchValue(term);
      });
    },
    300,
    [term],
  );

  const { locale, ...router } = useRouter();

  const { data, isValidating } = useSWR<
    ParsedProductsApiResponse<SnickersProduct>
  >(
    !passive &&
      searchValue &&
      settings.pt?.searchSection &&
      `/api/searchlist/${settings.pt.searchSection}?${new URLSearchParams({
        includeFilters: 'false',
        pageSize: `${SEARCH_BOX_PAGE_SIZE}`,
        searchValue,
        ...(locale && locale !== 'com' ? { locale } : {}),
      }).toString()}`,
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
    },
  );

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    startTransition(() => {
      setTerm(event.target.value);
    });
  };

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    if (term.length) {
      cancel();
      const query = new URLSearchParams({ q: term }).toString();
      router.push(`/${routes.SEARCH}?${query}`);

      clearSearch();
      toggleMenu?.();
    }
  };

  const clearSearch = () => {
    startTransition(() => {
      setTerm('');
      setSearchValue('');
      cancel();
    });
  };

  useKey('Escape', clearSearch);

  useEffect(() => {
    if (!isOpen) {
      clearSearch();
    }
  }, [isOpen]);

  const resultMapper = (product: SnickersProduct) => {
    const key = `SearchResult-${product.productId}`;
    return <ProductListItem key={key} product={product} narrow />;
  };

  return (
    <SearchBoxStyled $tall={tall} $wide={wide} $transparent={transparent}>
      <SearchInputStyled
        onSubmit={onSubmit}
        onChange={onChange}
        onClear={clearSearch}
        placeholder={placeholder || global?.search || ''}
        value={term}
        transparent={transparent}
        tall={tall}
      />
      <Result
        $wide={wide}
        $show={!!searchValue || !!data}
        $inMobileMenu={inMobileMenu}
      >
        <ResultHeading>{global?.suggestions}</ResultHeading>

        {isValidating && (
          <ResultLoading>
            <Loader small center />
          </ResultLoading>
        )}
        {!isValidating && data && !data.items?.length && (
          <ResultEmpty>
            {global.noSearchResults
              ? global.noSearchResults.replace('$1', searchValue)
              : ''}
          </ResultEmpty>
        )}
        {!isValidating && data?.items?.map(resultMapper)}
        {!isValidating && searchValue && !!data?.items?.length && (
          <ResultLink href={`/search?q=${searchValue}`}>
            {global?.viewAll || ''}
          </ResultLink>
        )}
      </Result>
    </SearchBoxStyled>
  );
};
