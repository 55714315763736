import { startTransition, useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import Image from 'next/image';

import { getLanguages } from '@hultafors/shared/api';
import { Language } from '@hultafors/shared/types';

import { markets } from '@hultafors/snickers/api';
import { useGlobal } from '@hultafors/snickers/hooks';
import { IconLinkFragment, LinkRecord } from '@hultafors/snickers/types';

import {
  BottomSection,
  ContactLinkRow,
  Content,
  CookieSettings,
  FooterFunctions,
  FooterLink,
  FooterStyled,
  FooterTop,
  IntroText,
  LinkList,
  LinkListItem,
  Logo,
  MiddleSection,
  PartnerLoginLink,
  SocialMedia,
  SocialMediaLink,
  SubscribeAreaWrapper,
  UnderlinedLink,
} from './FooterOld.styled';
import LoginSvg from './login-link-white.svg';
// import LogoSvg from './logo-small.svg';
import LogoSvg from './snickers-workwear-50-emblem.svg';

const Grid = dynamic(() =>
  import('../Grid/Grid').then((module) => module.Grid),
);
const GridChild = dynamic(() =>
  import('../GridChild/GridChild').then((module) => module.GridChild),
);
const Paragraph = dynamic(() =>
  import('../Paragraph/Paragraph').then((module) => module.Paragraph),
);
const Section = dynamic(() =>
  import('../Section/Section').then((module) => module.Section),
);
const Markdown = dynamic(() =>
  import('../Markdown/Markdown').then((module) => module.Markdown),
);
const SubscribeArea = dynamic(() =>
  import('../SubscribeArea/SubscribeArea').then(
    (module) => module.SubscribeArea,
  ),
);

const LanguageSelector = dynamic(() =>
  import('../LanguageSelector/LanguageSelector').then(
    (mod) => mod.LanguageSelector,
  ),
);

export const FooterOld: React.FC = () => {
  const { footer, global, settings } = useGlobal();
  const [languages, setLanguages] = useState<Language[]>([]);

  const handleOnPressSettings = () => {
    // TODO fix TS error
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.CookieScript.instance.show();
  };

  useEffect(() => {
    startTransition(() => {
      setLanguages(getLanguages(settings?.market, markets));
    });
  }, [settings?.market]);

  const socialMediaMapper = ({ id, url, icon }: IconLinkFragment) => {
    if (url) {
      const temp = new URL(url).hostname.match(/(\w+\.)?(.+)\.('co\.uk'|\w+)/);
      const length = temp?.length || 0;
      const match = temp?.[length - 2] || '';
      const alt = `${match[0]?.toUpperCase() || ''}${match.substring(1)}`;
      return (
        <SocialMediaLink
          key={`SocialMediaLink-${id}`}
          href={url || ''}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={alt}
        >
          <Image
            src={icon?.url || ''}
            alt={alt}
            aria-hidden={true}
            width={40}
            height={40}
            sizes="40px"
          />
        </SocialMediaLink>
      );
    } else {
      return null;
    }
  };

  const footerLinkMapper = (
    item: Pick<LinkRecord, 'id' | 'title' | 'url'>,
    index: number,
  ) => {
    return (
      <LinkListItem key={`FooterLink-${index}-${item.id}`}>
        <FooterLink href={item.url || ''}>{item.title}</FooterLink>
      </LinkListItem>
    );
  };

  const brandLinkMapper = (
    item: Pick<LinkRecord, 'id' | 'title' | 'url'>,
    index: number,
  ) => {
    return (
      <LinkListItem key={`FooterLink-${index}-${item.id}`}>
        <FooterLink href={item.url || ''} target="_blank">
          {item.title}
        </FooterLink>
      </LinkListItem>
    );
  };

  return (
    <FooterStyled>
      <Section>
        <Grid>
          <GridChild>
            <FooterTop>
              <Logo>
                <LogoSvg
                  width={48}
                  height={48}
                  title="Snickers Workwear logotype"
                />
              </Logo>

              <FooterFunctions>
                {global?.partnerPortalUrl && global?.partnerPortalLinkLabel && (
                  <Paragraph>
                    <PartnerLoginLink
                      href={global?.partnerPortalUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {global?.partnerPortalLinkLabel}
                      <LoginSvg
                        aria-hidden={true}
                        focusable={false}
                        width={16}
                        height={16}
                      />
                    </PartnerLoginLink>
                  </Paragraph>
                )}
                {languages.length > 1 && (
                  <LanguageSelector
                    chooseLanguageLabel={global?.changeLanguage || ''}
                    infoText=""
                    languages={languages}
                  />
                )}
              </FooterFunctions>
            </FooterTop>

            <MiddleSection id="footer-middle-section">
              <Content>
                <IntroText>
                  <Markdown>{footer?.introText || ''}</Markdown>
                </IntroText>
                <LinkList>
                  {footer?.hultaforsBrands?.map(brandLinkMapper)}
                </LinkList>

                {footer?.contactInfo &&
                  footer.contactUrl &&
                  footer.contactLinkLabel && (
                    <ContactLinkRow>
                      {footer.contactInfo}{' '}
                      <UnderlinedLink href={footer.contactUrl}>
                        {footer.contactLinkLabel}
                      </UnderlinedLink>
                    </ContactLinkRow>
                  )}
              </Content>
              <FooterFunctions />
              {footer?.dotDigitalFormUrl && (
                <SubscribeAreaWrapper>
                  <SubscribeArea
                    subscribeInfoText={footer?.subscribeInfoText || ''}
                    subscribeButtonText={footer?.subscribeCtaButton || ''}
                    userEmailFormUrl={footer?.dotDigitalFormUrl || ''}
                    userEmailFormImage={footer?.dotDigitalImage || undefined}
                  />
                </SubscribeAreaWrapper>
              )}
            </MiddleSection>
            <BottomSection>
              <LinkList>
                {footer?.menu?.map(footerLinkMapper)}

                {footer?.cookieSettings && (
                  <LinkListItem>
                    <CookieSettings onClick={handleOnPressSettings}>
                      {footer.cookieSettings}
                    </CookieSettings>
                  </LinkListItem>
                )}
              </LinkList>

              <SocialMedia>
                {footer?.socialMediaMenu?.map(socialMediaMapper)}
              </SocialMedia>
            </BottomSection>
          </GridChild>
        </Grid>
      </Section>
    </FooterStyled>
  );
};
