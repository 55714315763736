import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

import { colors, fontSizes, fontWeights } from '@hultafors/snickers/helpers';

const imageHeight = '360';

export const PersonInfoBlockStyled = styled.div`
  margin-block-end: 20px;

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block-end: 0;
  }

  .IntervieweeImage {
    inline-size: 100%;

    @media screen and (min-width: ${breakpoints.desktop}) {
      block-size: ${imageHeight}px;
      object-fit: cover;
    }
  }
`;

export const Details = styled.div`
  background-color: ${colors.gray4};
  z-index: 1;
  margin-block-start: -60px;
  width: 87.5%;
  padding: 24px 16px 28px;

  @media screen and (min-width: ${breakpoints.largeMobile}) {
    margin: 0;
    inline-size: 100%;
  }

  p {
    margin-block: 0 8px;

    &:last-of-type {
      margin-block-end: 0;
    }
  }

  .IntervieweeLabel {
    font-weight: ${fontWeights.medium};
  }
`;

export const Container = styled.div`
  max-width: 940px;
  margin-inline: auto;
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: auto;
  gap: 20px;

  @media screen and (min-width: ${breakpoints.largeMobile}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    gap: 30px;
  }
`;

export const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const BylineWrapper = styled.div`
  @media screen and (min-width: ${breakpoints.largeMobile}) {
    padding-block-end: 30px;
  }
`;

export const IntervieweeName = styled.h3`
  font-size: ${fontSizes.heroTitle};
  line-height: ${fontSizes.heroTitle};
  font-weight: ${fontWeights.bold};
`;
